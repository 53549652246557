<style >
	.el-radio__label,
	.el-checkbox__label {
		color: rgba(0, 0, 0, 0.85);
		font-size: 14px;
	}
	.el-empty__description {
		text-align: center;
	}

	.el-link--inner {
		width: 100%;
		font-size: 13px;
		padding: 0 20px;
	}
	.el-checkbox__label,
	.el-radio__label {
		white-space: normal;
	}

	.el-checkbox__input,
	.el-radio__input {
		vertical-align: top;
	}
	h3 {
		font-weight: 400;
	}

	.el-progress-bar__outer {
		background: #ffffff55;
	}
</style>
<template>
	<div style="padding:16px">
		<h1 style="color:#fff;text-align:center">密评知识答题系统</h1>
		<h6 style="text-align:right;color:#ffffffee"> 剩余时间：{{timer}}s</h6>
		<el-progress :percentage="timer/90*100" :stroke-width="8" :show-text="false" status="warning" style="margin-bottom:20px" />
		<div style="width:100%;text-align:center">
			<img src="../../assets/ex_title.png" alt="">
		</div>
		<el-card shadow="always" style="margin-bottom:20px">
			<h3>{{item.title}}</h3>
			<template v-if="item.type==1">
				<h4 style="margin:20px 0 0 0">
					<el-radio-group v-model="ans1">
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="A">A. {{item.opt1}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="B">B. {{item.opt2}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="C">C. {{item.opt3}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="D" v-if="item.opt4!=''">D. {{item.opt4}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="E" v-if="item.opt5!=''">E. {{item.opt5}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="F" v-if="item.opt6!=''">F. {{item.opt6}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="G" v-if="item.opt7!=''">G. {{item.opt7}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="H" v-if="item.opt8!=''">H. {{item.opt8}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="I" v-if="item.opt9!=''">I. {{item.opt9}}</el-radio><br />
					</el-radio-group>
				</h4>
				<h4 v-show="is_show" style="color:red">正确答案：{{item.ans}}</h4>
				<h4 style="text-align:center"><el-button v-show="is_show" type="danger" size="mini" style="margin:10px auto 0 auto" round plain icon="el-icon-lock">注册会员 查看解析</el-button></h4>
				<!-- <h4 v-show="is_show" style="color:red">答案解析：{{item.reason}} </h4> -->
			</template>
			<template v-else-if="item.type==2">
				<h4 style="margin:20px 0 0 0">
					<el-checkbox-group v-model="ans2">
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="A">A. {{item.opt1}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="B">B. {{item.opt2}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="C">C. {{item.opt3}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="D">D. {{item.opt4}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="E" v-if="item.opt5!=''">E. {{item.opt5}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="F" v-if="item.opt6!=''">F. {{item.opt6}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="G" v-if="item.opt7!=''">G. {{item.opt7}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="H" v-if="item.opt8!=''">H. {{item.opt8}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="I" v-if="item.opt9!=''">I. {{item.opt9}}</el-checkbox><br />
					</el-checkbox-group>
				</h4>
				<h4 v-show="is_show" style="color:red">正确答案：{{item.ans}}</h4>
				<h4 style="text-align:center"><el-button v-show="is_show" type="danger" size="mini" style="margin:10px auto 0 auto" round plain icon="el-icon-lock">注册会员 查看解析</el-button></h4>
			</template>
			<template v-else-if="item.type==3">
				<h4 style="margin:20px 0 0 0">
					<el-radio-group v-model="ans3">
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="1">
							<i class="el-icon-check" style="color:green;font-weight:700"></i> 正确
						</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="0">
							<i class="el-icon-close" style="color:red;font-weight:700"></i> 错误
						</el-radio>
					</el-radio-group>
				</h4>
				<h4 v-show="is_show" style="color:red">正确答案：
					<i v-if="item.ans==1" class="el-icon-check" style="color:green;font-weight:700"></i>
					<i v-else class="el-icon-close" style="color:red;font-weight:700"></i>
				</h4>
				<h4 style="text-align:center"><el-button v-show="is_show" type="danger" size="mini" style="margin:10px auto 0 auto" round plain icon="el-icon-lock">注册会员 查看解析</el-button></h4>
			</template>
		</el-card>

		<el-button type="warning" size="medium" round @click="show_right" icon="el-icon-s-comment">查看答案</el-button>
		<el-button type="warning" size="medium" round @click="get_next" icon="el-icon-caret-right">下一题</el-button>

		<!-- </el-col> -->
		<!-- <el-col :span="12">
								<h3>
									刷题进度：{{num}}/{{max_num}}
								</h3>
								<el-progress :percentage="percent" style="width:400px;margin:20px 0;" />
							</el-col> -->
		<!-- </el-row> -->

		<!-- </div> -->
		</el-card>
	</div>
</template>
<script>
	import { MessageBox } from "element-ui";
	import request from "../../plugins/axios.js";
	export default {
		mounted() {
			document.title = "答题系统";
			// request({
			// 	url: "/Study/re_exam_all",
			// 	data: {},
			// }).then((ret) => {
			// 	if (ret.code == 1) {
			// 		this.list = ret.data.list;
			// 	} else {
			// 		this.$router.push("/user/exam");
			// 	}
			// });
			// request({
			// 	url: "/Study/ex_all",
			// 	data: {},
			// }).then((ret) => {
			// 	if (ret.code == 1) {
			// 		this.ex_table = ret.data.ex_list;
			// 	} else {
			// 		this.$router.push("/user/exam");
			// 	}
			// });
			// request({
			// 	url: "/Study/score_all",
			// 	data: {},
			// }).then((ret) => {
			// 	if (ret.code == 1) {
			// 		this.score_table = ret.data.score;
			// 	} else {
			// 		this.$router.push("/user/exam");
			// 	}
			// });
			this.get_next();
		},
		methods: {
			// re_exam(uuid) {
			// 	MessageBox.confirm("重新考试将删除之前的答题记录, 是否继续?", "提示", {
			// 		confirmButtonText: "确定",
			// 		cancelButtonText: "取消",
			// 		type: "warning",
			// 	})
			// 		.then(() => {
			// 			request({
			// 				url: "/study/re_exam_clear/" + uuid,
			// 				data: {},
			// 			}).then((ret) => {
			// 				if (ret.code == 1) {
			// 					this.$router.push("/user/re_exam/" + uuid);
			// 				}
			// 			});
			// 		})
			// 		.catch(() => {});
			// },
			//查看结果
			show_right() {
				this.is_show = true;
			},
			//下一题
			get_next() {
				clearInterval(this.t);
				request({
					url: "/Login/exercise",
					data: {},
				}).then((ret) => {
					if (ret.code == 1) {
						this.item = ret.data.exercise;
						this.num = ret.data.num;
						this.max_num = ret.data.max_num;
						this.percent = ((this.num / this.max_num) * 100.0).toFixed(2);
						this.ans1 = "";
						this.ans2 = [];
						this.ans3 = "";
						this.is_show = false;
						this.timer = 90;
						// request({
						// 	url: "/Study/ex_all",
						// 	data: {},
						// }).then((ret) => {
						// 	if (ret.code == 1) {
						// 		this.ex_table = ret.data.ex_list;
						// 	} else {
						// 		this.$router.push("/user/exam");
						// 	}
						// });
						this.t = setInterval(() => {
							this.timer = this.timer - 1;
							if (this.timer <= 0) {
								clearInterval(this.t);
							}
						}, 1000);
					}
					// else {
					// this.$router.push("/user/exam");
					// }
				});
			},
		},
		data() {
			return {
				name: window.localStorage.getItem("name"),
				score_table: [],
				ex_table: [],
				list: [],
				item: {},
				ans1: "",
				ans2: [],
				ans3: "",
				timer: 90,
				num: 0,
				max_num: 0,
				percent: 0,
				t: null,
				is_show: false,
			};
		},
	};
</script>